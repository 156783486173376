<template>
  <div class="mt-5 mb-4 col-sm-12 col-xl-11">
    <div class="mb-5 d-flex jusfity-space-between align-center">
      <div class="body-2 font-weight-bold">TICKETS</div>

      <div class="col-sm-12 col-md-3 ml-auto d-flex align-center">
        <v-select
          :items="tstatus"
          item-text="text"
          item-value="value"
          v-on:input="sort($event)"
          v-model="$store.state['admin'].ticket_status"
        />

        <v-btn icon class="ml-5" @click="reload">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left caption">Ticket #</th>
            <th class="text-left caption">Email</th>
            <th class="text-left caption">Category</th>
            <th class="text-left caption">Priority</th>
            <th class="text-left caption">Status</th>
            <th class="text-left caption">Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in tickets.data"
            :key="item.id"
            @click="status(item)"
            style="cursor: pointer"
          >
            <td class="body-2">{{ item.id }}</td>
            <td class="body-2">{{ item.user.email }}</td>
            <td class="body-2">{{ item.category }}</td>
            <td class="body-2">
              {{ $helpers.prioType(item.priority) }}
              <v-icon :color="$helpers.prioColor(item.priority)"
                >mdi-circle-medium</v-icon
              >
            </td>
            <td class="body-2" :class="item.status == 2 ? 'success--text' : ''">
              {{ $helpers.ticketStatus(item.status) }}
            </td>
            <td class="body-2">{{ $helpers.dateDiff(item.created_at) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <Paginate class="mt-5" store="admin" collection="tickets" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Paginate from "./TicketPaginate.vue";

export default {
  components: {
    Paginate,
  },
  data: () => ({
    tstatus: [
      { text: "Pending", value: 0 },
      { text: "Ongoing", value: 1 },
      { text: "Done", value: 2 },
    ],
  }),
  computed: {
    ...mapState("admin", {
      tickets: (state) => state.tickets,
      ticket_status: (state) => state.ticket_status,
    }),
  },
  mounted() {
    this.getTickets({
      page: 0,
      status: this.ticket_status,
    });
  },
  methods: {
    ...mapActions("admin", ["getTickets"]),
    ...mapMutations("admin", ["ticketsMutation"]),

    status(item) {
      this.$router.push({
        name: "Admin Ticket",
        params: { uuid: item.uuid },
      });
    },

    sort(e) {
      console.log(e);
      this.getTickets({
        page: 0,
        status: e,
      });
    },

    reload() {
      this.getTickets({
        page: 0,
        status: "",
      });

      this.$store.state["admin"].ticket_status = "";
    },
  },

  beforeDestroy() {
    this.ticketsMutation([]);
  },
};
</script>